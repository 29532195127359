import { apiClient } from "../infrastructure/api/apiClient";

export const getActions = () => {
  return apiClient.get('/action')
  .then((response)=> {    
    return response.data;
  });
};

export const getActionDetails = (id:string) => {
  return apiClient.get(`/action/details/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const createAction = (payload: any) => {
       
    return apiClient.post('/action/create', payload)
    .then((response)=> {    
      return response.data;
    });
};

export const uploadFile = (files: any[]) => {
  
  const formData = new FormData();   
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }     

  return apiClient.post('/action/file-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
  .then((response)=> {    
    return response.data;
  });
};

export const updateAction = (id:string, payload: any) => {
  return apiClient.put(`/action/${id}`, payload)
  .then((response)=> {    
    return response.data;
  });
};

export const deleteAction = (id:string) => {
  return apiClient.delete(`/action/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const getActionAssessments = (id:string) => {
  return apiClient.get(`/action/get-assessments/${id}`)
  .then((response)=> {    
    return response.data;
  });
};

export const updateAssessments = async (id:string, payload: any) => {
  return await apiClient.put(`/action/${id}/assessment`, payload)
  .then((response)=> {    
    return response.data;
  });
};



