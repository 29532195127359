import * as React from 'react';
import styled from "styled-components";
import ProfileImageComponent from '../../components/ProfileImage/ProfileImage';
import {toTitleCase} from "../../utils/title-case.util";

const Iteam = styled.tr`
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        background-color: #dbdbdb;
    }
`;

const IdIteam = styled.td`
    width: 20px;
`;

const ItemFname = styled.td`
    display: flex;
    margin-top: 10px;
    width: 220px;
`;

const Fname = styled.div`
    padding: 5px;
    padding-left: 10px;
`;

const SectionItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProfileImage = styled.div``;

const EmailItem = styled.div`
    padding-left: 10px;
    font-size: 13px;
    color: #ababab;
    padding-bottom: 7px;
`;

const StateContainer = styled.div<{state: string}>`
    border-radius: 4px;
    padding: 4px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${({ state }) => {
    switch (state) {
        case 'OFFBOARDING':
            return '#b2bec3';        
        case 'ONBOARDING':
            return '#fdcb6e';
        default:
            return '#b2bec3';
    }
}};
`;

interface Props {
    id: string | number;
    firstName: string;
    lastName: string;
    position: string;    
    boardingState: string;
    team: string;
    chapterLead: string;
    createdAt: string;
    onClick?: React.MouseEventHandler;
}
const EmployeeTableItem: React.FunctionComponent<Props> = ({ id, firstName, lastName, boardingState,  position, chapterLead, team, createdAt, onClick }) => {
    return <Iteam onClick={onClick} key={id}>
            <IdIteam></IdIteam>
            <ItemFname>
            <ProfileImage>
                <ProfileImageComponent firstName={ firstName } lastName = {lastName || ''} />
            </ProfileImage>
                <SectionItem>
                <Fname>{ firstName } { lastName }</Fname> <EmailItem>{ position }</EmailItem></SectionItem></ItemFname>                        
            <td> { team } </td>
            <td> { boardingState === 'COMPLETED'? toTitleCase(boardingState) : <StateContainer state={boardingState}> {toTitleCase(boardingState)} </StateContainer> } </td>
            <td> { chapterLead } </td>
            <td>{  createdAt }</td>
        </Iteam>
}

export default EmployeeTableItem;
