import React, { useState } from 'react';
import styled from 'styled-components';

const DropdownSelectBoxWrapper = styled.div`
    width: 100%;
    height: 30px;
    font-size: 14px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    padding-left: 10px;
    border-radius: 5px;
    &:focus {
        outline: none;
    }
`;

const SelectedOption = styled.div`
    padding-top: 7px;
`;

const Options = styled.div`
    position: relative;
  background: #ffffff;
  border: 1px solid #ebebeb;
  margin-left: -11px;
  padding-top: 7px;
  width: 70%;
  margin-top:6px;
  z-index: 999;
`;

const ResultWrapper = styled.div`
  white-space: nowrap;
  overflow-y: auto; 
  max-height: 200px;
`;

const Option = styled.div`
    padding: 15px;
    height: 20px;
    &:hover {
      background: #eff6f9;
      cursor: pointer;
  }
`;

const Position = styled.div`
    padding-left: 15px;
    font-size: 12px;
    color: #515251;
    margin-top: -15px;
`;

const SearchBox = styled.div`
  padding: 10px;
`;

const SearchInput = styled.input`
  border: 1px solid #ebebeb;
  width: 200px;
  border-radius: 7px;
  padding: 10px;
  &:focus {
        outline: none;
    }
`;

const Label = styled.div`
    font-size: 12px;
    color: #515251;
    margin-top: 15px;
    margin-bottom: 5px;
`;

const ArrowDown = styled.div` 
  border: solid #515251;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  float: right; 
  margin-top:3px;
  margin-right:15px;
`;

const ArrowUp = styled.div` 
  border: solid #515251;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  position: relative;
  float: right;
  margin-top:5px;
  margin-right:15px;
`;

const EmptyOption = styled.div`
  padding: 15px;
  font-family: sans-serif;
  font-size:12px;
`;

interface DropdownSelectBoxProps {
  options: {value: number | string, label: string, position?: string} [];
  selectedOption?: any;
  label?: string;
  onChange: (option: any) => void;
}

const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

const DropdownSelectBox: React.FC<DropdownSelectBoxProps> = ({ options, selectedOption, label, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredOptions = options.filter((option) => {
    return {
      label: option.label.toLowerCase().includes(searchQuery.toLowerCase()),
      position: option.position
    };
  });

  const handleOptionClick = (option: any) => {
    onChange( option);
    setIsOpen(false);
    setSearchQuery('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setIsOpen(true);
  };

  return (
    <>
    <Label>{ label }</Label>
    <DropdownSelectBoxWrapper>
      <SelectedOption onClick={() => setIsOpen(!isOpen)}>
        {truncate(selectedOption?.label, 50)}
        {isOpen ? <ArrowUp/> : <ArrowDown/>}
      </SelectedOption>
      {isOpen && (
        <Options>
          {(options.length > 4)? 
           <SearchBox>
           <SearchInput type="text" placeholder="Search..." value={searchQuery} onChange={handleInputChange}  autoComplete="new-password"/>
         </SearchBox> : null}         
          <ResultWrapper>
          {filteredOptions.length? filteredOptions.map((option) => (
              <>
              <Option key={option.value} onClick={() => handleOptionClick(option)}>
                {truncate(option.label, 100)}

              </Option>
                {option.position ? <Position>{ option.position }</Position> : null }
              </>
          )) : <EmptyOption>There is no any items...</EmptyOption>}
          </ResultWrapper>
        </Options>
      )}
    </DropdownSelectBoxWrapper>
  </>
  );
};

export default DropdownSelectBox;
