import * as React from 'react';
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardLayout from '../../../layouts/DashboardLayout';
import { getActionDetails, getActionAssessments } from '../../../services/actions.service';
import Label from '../../../components/Label/Label.component'
import ActionDetailsTabFeature from '../../../features/workflows/tabs/ActionDetailsTab.featue';
import {ActionType, getReadableActionType} from "../../../utils/action-types.utils";
import {transformRevertibleString} from "../../../utils/revertible-format";

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Form = styled.div`
    width: 50%;
`;

const BoxItem = styled.div`
    box-sizing: border-box;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: rgb(225, 227, 234);
    box-shadow: rgba(18, 28, 45, 0.1) 0 2px 8px 0;
    min-width: 180px;
    margin: 10px;
`;

const BoxTitle = styled.div`
  font-weight: bold;
`;
const BoxContent = styled.div`
  padding-top: 15px;
`;

const FormButtonsItem = styled.div`
    display: flex;
    gap:30px;
    flex-wrap: nowrap;
    margin-top: 15px;
    border-bottom : 1px solid  lightgray;
    padding-bottom: 20px;
`;

function ActiondDetailsPage() {  
    
    const [action, setAction] = useState<any>(() => null);        
    const [assessmentsData, setAssessmentsData] = useState<any>(() => null);   
   

    let { id } = useParams();
    React.useEffect(() => {      
        async function getAction() {            
            var action = await getActionDetails(id);                
            setAction(action);            
        }
        getAction();
        
    },[id]); 
    
    React.useEffect(() => {      
        async function getAssessments() {            
            const response = await getActionAssessments(id);
            console.log(response);
            setAssessmentsData(response);            
        }
        getAssessments();
        
    },[id]);
       

  return <>
  <DashboardLayout>
  <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Actions</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Action details page</div>                
            </div>
            
    {(action) &&
    <Form>  
    <FormButtonsItem>
        <BoxItem>
            <BoxTitle>Name</BoxTitle>
            <BoxContent>{action.name}</BoxContent>
        </BoxItem>                
        <BoxItem>
            <BoxTitle>Assigned To</BoxTitle>
            <BoxContent>{`${action.assignee.firstName} ${action.assignee.lastName}`}</BoxContent>
        </BoxItem>
        <BoxItem>
            <BoxTitle>Action Type</BoxTitle>
            <BoxContent>{getReadableActionType(action.actionType as ActionType)}</BoxContent>
        </BoxItem>
        <BoxItem>
            <BoxTitle>Revokable</BoxTitle>
            <BoxContent>{transformRevertibleString(action.reversibleType)}</BoxContent>
        </BoxItem>        
    </FormButtonsItem>      
    <FormItem>        
    </FormItem>    
    { action.description &&
    <FormItem>
        <Label label="Description" text={action.description} />
    </FormItem>   }  
    { (assessmentsData) && (action.actionType === 'DOCUMENTATION_TRAINING') ?
    < ActionDetailsTabFeature files={action.files}  assessmentsData={assessmentsData} actionId={action.id}    />
    : null
    }
    </Form> }
    </DashboardLayout>
  </>
}

export default ActiondDetailsPage;