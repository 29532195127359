import styled from "styled-components";
import { useState } from "react";
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import SentComponent from "../../components/Sent/Sent.component";
import InputTextComponent from "../../components/input/InputText.component";
import {createEmployeeTask} from "../../services/tasks.service";
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const ErrorWrapper = styled.div`
  width: auto;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;


const Button = styled.div`
    min-width: 100px;
    max-width: 200px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    employeeId?: string;
    actionsList?: any;
}

const AssignEmployeeTaskFeature:React.FC<InputTextProps> = ({ employeeId, actionsList })=> {
    const [notSent, setnotSent] = useState(true);
    const [startDate, setStartDate] = useState("");
    const actionsListItems = actionsList.length ? actionsList.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Action"}];
    const [selectedActionsOption, setSelectedActionsOption] = useState(actionsListItems[0]);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const priorities = [{value: "LOW", label: "Low"}, {value: "MEDIUM", label: "Medium"}, {value: "HIGH", label: "High"}];
    const statusList = [
        {
            value: "TODO", label: "To do"
        },
        {
            value: "IN_PROGRESS", label: "In Progress"
        },
        {
            value: "COMPLETED", label: "Completed"
        }
    ];
    const [selectedPriorityOption, setSelectedPriorityOption] = useState(priorities[0]);
    const [selectedStatusListOption, setSelectedstatusListOption] = useState(statusList[0]);
    const handleOptionPriorityChange = (option: any) => {
        setSelectedPriorityOption(option);
        setPriority(option.value);
    };
    const [priority, setPriority] =  useState(priorities[0].value);

    const handleOptionStatusListChange = (option: any) => {
        setSelectedstatusListOption(option);
        setStatus(option.value);
    };
    const [status, setStatus] =  useState(statusList[0].value);


    const handleDate = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleActionsOptionChange = (option: any) => {
        setSelectedActionsOption(option);
    };


    const handleSubmit = async ()=> {
        try {
            await createEmployeeTask({
                actionId: selectedActionsOption.value,
                priorityId: priority,
                dueDate: startDate,
                status: status,
            }, employeeId);
            setnotSent(false);
        } catch (e) {
            console.log(e);
            setErrorMessage(e.response.data.message);
            setError(true);
        }
    }

    return <>
        {notSent ?  <div>
            <div className="errorMessage">
                {error ? <ErrorWrapper>{ errorMessage }</ErrorWrapper> : null}
            </div>
            <FormItem>
                <DropdownSelectBox label="Choose a Task"  options={actionsListItems} selectedOption={selectedActionsOption} onChange={handleActionsOptionChange} />
            </FormItem>
            <FormItem>
                <DropdownSelectBox label="Priority"  options={priorities} selectedOption={selectedPriorityOption} onChange={handleOptionPriorityChange} />
            </FormItem>
            <FormItem>
                <InputTextComponent label="Due Date" onChildStateChange = {handleDate} fullWidth={true} type="date"  placeholder="Choose Date" />
            </FormItem>
            <FormItem>
                <DropdownSelectBox label="Status"  options={statusList} selectedOption={selectedStatusListOption} onChange={handleOptionStatusListChange} />
            </FormItem>
            <FormItem>
                <Button onClick = { () => handleSubmit() }>Assign Task</Button>
            </FormItem></div> : <SentComponent title = "Task has been added!" />}
    </>
};

export default AssignEmployeeTaskFeature;
