import * as React from "react";
import AccountLayout from "../../layouts/AccountLayout";
import styled from "styled-components";
import InputTextComponent from '../../components/input/InputText.component';
import { changePassword } from '../../services/user.service';
import { InfoMessageComponent, MessageTypes } from '../../components/InfoMessage/InfoMessage.component';
import PasswordStrengthComponent from "../../components/PasswordStrength/PasswordStrength.component";
import { PasswordStrengthStatuses}  from "../../components/PasswordStrength/PasswordStrength.component";
import ButtonComponent from "../../components/Buttons/Button.component";


const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;   
`;

const ActionsForm = styled.div`
  display: flex;
  flex-direction: row;  
  gap: 15px;
  margin-top: 15px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 250px;
`;

const UserSecurityPage: React.FunctionComponent = () => {
  
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState(''); 
  const [confirmPassword, setConfirmPassword] = React.useState(''); 
  const [isRepeatPasswordSame, setisRepeatPasswordSame] = React.useState(false); 
  const [errorMessage, setErrorMessage] = React.useState(''); 
  const [showError, setShowError] = React.useState(false); 
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [passwordStrengthStatus, setPasswordStrengthStatus] = React.useState<PasswordStrengthStatuses>();

  const handleCurrentPasswordChange = (event: any) => {       
    setCurrentPassword(event.target.value);
    hideErrorMessage();
  };

  const handleNewPasswordChange  = (event: any) => {   
    setNewPassword(event.target.value);
    hideErrorMessage();
  };

  const handleRepeatPasswordChange  = (event: any) => {
    setConfirmPassword(event.target.value);
    setisRepeatPasswordSame(event.target.value === newPassword);
    hideErrorMessage();
  };

  const handleChangePassword = async () => {
    hideErrorMessage();
    hideSuccessMessage();    

    if(!currentPassword || !newPassword || !confirmPassword )
    {
      showErrorMessage('All fields is required!');
      return;
    }

    if(!isRepeatPasswordSame) 
    {     
      showErrorMessage('Password does not match!');
      return;
    }
    
    if(passwordStrengthStatus !== PasswordStrengthStatuses.STRONG)
    {
      showErrorMessage('You does not set a strong password!');
      return;
    }
    
      await changePassword(currentPassword, newPassword)
        .then(() => setShowSuccess(true))
        .catch(() => showErrorMessage('Password update failed!'));               
  }

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  }

  const hideErrorMessage = () => {
    setErrorMessage('');
    setShowError(false);
  }

  const hideSuccessMessage = () => {
    setShowSuccess(false);
  }

  const handlePasswordStrengthCheck = async (status: PasswordStrengthStatuses ) => {     
    setPasswordStrengthStatus(status);
  }

  return (
    <>
    
      <AccountLayout>      
      <Wrapper>
        <h3>Change Password</h3>       
       <FormItem>         
        <InputTextComponent
          label='Current password'
          type="password"         
          onChildStateChange={handleCurrentPasswordChange} 
          value={currentPassword}
        />     
       </FormItem>
       <FormItem>         
        <InputTextComponent
          label='New password'
          type="password"          
          onChildStateChange={handleNewPasswordChange}
          value={newPassword}        
        />     
       </FormItem>
       <FormItem>                       
        { newPassword ? <PasswordStrengthComponent password={newPassword} onScoringFinished={handlePasswordStrengthCheck}/>
        : null }
       </FormItem>
       <FormItem>         
        <InputTextComponent
          label='Confirm password'
          type="password"          
          onChildStateChange={handleRepeatPasswordChange}
          value={confirmPassword}     
        />     
       </FormItem>       
       <FormItem>        
        {showError ? <InfoMessageComponent text={errorMessage} type={MessageTypes.Error} /> : null}                 
        {showSuccess ? <InfoMessageComponent text='Password was updated successfully' type={MessageTypes.Success} /> : null}        
       </FormItem>
       <ActionsForm>
                <ButtonComponent text="Update password" onClick={handleChangePassword} />
            </ActionsForm>
            </Wrapper>
      </AccountLayout>
    </>
  );
};

export default UserSecurityPage;
