import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getActions } from '../../../services/actions.service';
import ActionsTableIteam from './actions-table-items';
import ActionsTable from './actions-table.feature';
import { TabsEnum } from '../workflow/workflow-table.feature';
import { deleteAction } from '../../../services/actions.service';
import {transformRevertibleString} from "../../../utils/revertible-format";
import SpinnerWrapper from "../../../components/Spinner/SpinnerWrapper.component";
import {useState} from "react";
import {EmptyBox} from "@rocket-desert/shared-ui";
import ModalComponent from '../../../components/modal/modal.component';
import CreateActionFeature from '../flow/create-action.feature';
import useModal from '../../../hooks/useModal';
import { getUsers } from '../../../services/user.service';
import CreateButtonComponent from '../../../components/Buttons/CreateButton.component';
import styled from 'styled-components';

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 10px;
`;

const EmptyBoxgWrapper = styled.div`    
    margin-top: 20px;
`;

interface Props {
    title?: string;
    showTitle?: boolean;
    showSearch?: boolean;
    showControls?: boolean;
    tabType: TabsEnum;
}

const ActionsFeature: React.FunctionComponent<Props>  = ({ title, showTitle, showSearch, showControls }) => {
    const {isShowing, toggle} = useModal();
    const [actions, setActions] = React.useState<any[]>([]);    
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
   
    const getActionsList =  async  () => {
        setIsLoading(true);
        const actions = await getActions();
        setActions(actions);
        setIsLoading(false);
    }

    React.useEffect(()=> {       
        getActionsList();
    },[]);


    const [users, setUsers] =  React.useState<any[]>([]);
    React.useEffect(()=> {
        async function getUsersList() {
            var usersResponse: any = await getUsers();
            setUsers(usersResponse.users);
        }
        getUsersList();
    }, []);

    const actionsFetch = async () => {
        setIsLoading(true);
        const actions = await getActions();
        setActions(actions);
        setIsLoading(false);
    }; 

    const handleEdit = async (id: string) => {
        navigate('/action/edit/' + id);       
    };

    const handleView = async (id: string) => {
        navigate('/action/view/' + id);       
    };   

    const handleDelete = async (id: string) => {
       await deleteAction(id);
       getActionsList();
     };
    return <>
        <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title={title!}
                >
                {users.length? <CreateActionFeature fetchData={actionsFetch} users={users} /> : <EmptyBox message={'There is no any employee find who will  be assign for new action'}></EmptyBox> }
        </ModalComponent>
        <HeadingWrapper>
        {title && showControls ?
                <CreateButtonComponent
                    textColor="#ffffff"
                    color="#2a7aeb"
                    text="New Action"
                    onClick={toggle}
                />
            : null }
        </HeadingWrapper>
        {isLoading ? <SpinnerWrapper /> :  actions.length?
        <ActionsTable 
            title = { title } 
            showControls = { showControls } 
            showTitle = { showTitle } 
            showSearch = { showSearch }>
           { actions.map((item)=> {
               return  <ActionsTableIteam
               key = {item.id}
               id={item.id}
               name={item.name}
               status="ACTIVE"
               actionType={item.actionType}
               ReversibleType={ transformRevertibleString(item.reversibleType) }
               owners={item.assignee.firstName + ' ' + item.assignee.lastName}
               createdAt={item.createdAt}
               onView={() => handleView(item.id) }
               onEdit={() => handleEdit(item.id)} 
               onDelete={() => handleDelete(item.id)} />
           })}
     </ActionsTable> : <EmptyBoxgWrapper> <EmptyBox message='There is no any actions' /> </EmptyBoxgWrapper> }
    </>
}

export default ActionsFeature;