import React, { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../components/input/InputText.component";
import SentComponent from "../../components/Sent/Sent.component";
import {createAsset} from "../../services/catalog.service";
import { assetTypesList } from '@rocket-desert/common-lib';
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    width: 100px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    users?: any[];
    fetchAssets?: () => void;
}

const CreateCatalogFeature:React.FC<InputTextProps> = ({ title, users, fetchAssets })=> {
    const [notSent, setnotSent] = useState(true);

    const assetTypesLists = assetTypesList.length ? assetTypesList.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Industry"}];

    const [selectedAssetTypeOption, setSelectedAssetTypeOption] = useState(assetTypesLists[0]);
    const [assetType, setAssetType] =  useState(assetTypesLists[0].value);
    const handleOptionAssetTypeChange = (option: any) => {
        setSelectedAssetTypeOption(option);
        setAssetType(option.value);
    };

    const availabilityList = [{value: "AVAILABLE", label: "Available"}, {value: "NOT_AVAILABLE", label: "Not Available"}];
    const [selectedAvailabilityOption, setSelectedAvailabilityOptionOption] = useState(availabilityList[0]);
    const [availability, setAvailability] =  useState(availabilityList[0].value);
    const handleOptionAvailabilityChange = (option: any) => {
        setSelectedAvailabilityOptionOption(option);
        setAvailability(option.value);
    };

    const [assetName,setAssetName] = useState("");
    const handleAssetName = (event: any) => {
        setAssetName(event.target.value);
    };

    const [assetDescription,setAssetDescription] = useState("");
    const handleAssetDescription= (event: any) => {
        setAssetDescription(event.target.value);
    };

    const [assetCode,setAssetCode] = useState("");
    const handleAssetCode= (event: any) => {
        setAssetCode(event.target.value);
    };

    const [assetLicence,setAssetLicence] = useState("");
    const handleAssetLicence = (event: any) => {
        setAssetLicence(event.target.value);
    };

    const handleSubmit = async ()=> {
        try{
            await createAsset({
                name: assetName,
                assignee: assignedTo,
                description: assetDescription,
                code: assetCode,
                asset_type: assetType,
                availability: availability,
                licence: assetLicence
            });
            fetchAssets && fetchAssets();
            setnotSent(false);
        }
        catch(err){
            setnotSent(true);
            console.log(err);
        }
    }
    const options = users.map((item) => {
        return {value: item.id, label:  `${item.firstName} ${item.lastName}`, position: item.position.name};
    });
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [assignedTo, setAssignedTo] = useState(options[0].value);
    const handleOptionChange = (option: any) => {
        setAssignedTo(option.value);
        setSelectedOption(option);
    };

    return <>
        {notSent ? <div>
            <FormItem>
                <InputTextComponent onChildStateChange = {handleAssetName} fullWidth={true} label="Name" type="text"  placeholder="Asset name" />
            </FormItem>
            <FormItem>
                <DropdownSelectBox label="Assign to" options={options} selectedOption={selectedOption} onChange={handleOptionChange} />
            </FormItem>
            <FormItem>
                <InputTextComponent onChildStateChange = {handleAssetDescription} fullWidth={true} label="Description" type="text"  placeholder="Description" />
            </FormItem>
            <FormItem>
                <DropdownSelectBox label="Asset Type"  options={assetTypesLists} selectedOption={selectedAssetTypeOption} onChange={handleOptionAssetTypeChange} />
            </FormItem>
            <FormItem>
                <InputTextComponent onChildStateChange = {handleAssetCode} fullWidth={true} label="Serial Number" type="text"  placeholder="Serial number" />
            </FormItem>
            <FormItem>
                <InputTextComponent onChildStateChange = {handleAssetLicence} fullWidth={true} label="Licence" type="text"  placeholder="Licence" />
            </FormItem>
            <FormItem>
                <DropdownSelectBox label="Availability"  options={availabilityList} selectedOption={selectedAvailabilityOption} onChange={handleOptionAvailabilityChange} />
            </FormItem>
            <FormItem>
                <Button  onClick = { () => handleSubmit() }>Create</Button>
            </FormItem> </div> : <SentComponent title = {title} /> }
    </>
};

export default CreateCatalogFeature;
