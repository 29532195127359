import { useState, useEffect } from "react";
import BoxComponent from '../../components/Box/Box.component';
import DashboardLayout from '../../layouts/DashboardLayout';
import styled from "styled-components";
import OnboardingFeature from '../../features/boarding/onboarding/onboarding.feature';
import OffboardingFeature from '../../features/boarding/offboarding/offboarding.feature';
import { getMetrics } from "../../services/metrics.service";

const BoxList = styled.div`
    display: flex;
    margin-top: 10px;
`;
interface Props {
    children?: React.ReactNode
}
const HomePage: React.FunctionComponent<Props> = () => {
    const [metrics, setMetrics] = useState<any>([]);
    useEffect(()=> {
        async function getMetricsData() {
            const metrics = await getMetrics();
            setMetrics(metrics);
        }
        getMetricsData();
    },[]);

    return <>
        <DashboardLayout>
            <div>Home / Dashboard</div>
            <BoxList>
                <BoxComponent title="Teams" main= {metrics.teamsCount} measurment="team" border={true} />
                <BoxComponent title="Employees" main= {metrics.userCount}  measurment="employee" color="#42b36f" textColor="#ffffff" />
                <BoxComponent title="Onboardings" main= {metrics.onboardingCount}  measurment="employee" color="#f0f55f" />
                <BoxComponent title="Offboardings" main={metrics.offboardingCount} measurment="employee" color="#f58f5f" />
            </BoxList>
            <OnboardingFeature title="Latest Onboardings" showTitle={true} />
            <OffboardingFeature title = "Latest Offboardings" showTitle={true} />
        </DashboardLayout>
    </>
}

export default HomePage;