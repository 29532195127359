import styled from "styled-components"

const BoxWrapper = styled.div<{bgColor?: string, txtColor?: string, overHover?: string, hasBorder?: boolean}>`
    width: 140px;
    min-height: 50px;
    ${props => props.hasBorder ? `border: 1px solid #f2edf2;` : null}
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 10px 10px 0;
    background-color: ${props => props.bgColor};
    color: ${props => props.txtColor};

    &:hover {
        cursor: pointer;
        background-color: ${props => props.overHover};
    }

    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: rgb(225, 227, 234);
    box-shadow: rgba(18, 28, 45, 0.1) 0 2px 8px 0;
    min-width: 180px;
`;

const Title = styled.div`
    font-size: 17px;
`;

const Main = styled.div`
    display: flex;
`;

const Measurement = styled.div`
    font-size: 14px;
    padding-top: 7px;
    padding-left: 5px;
    opacity: 0.8;
`;

const MainBold = styled.div`
    font-size: 22px;
    font-weight: 600;
`;

interface BoxProps{
    title: string;
    main: string;
    measurment?: string;
    color?: string;
    textColor?: string;
    hoverColor?: string;
    border?: boolean;
 }

const BoxComponent:React.FC<BoxProps> = ({ title, main, measurment, color, textColor, hoverColor, border })=> (
  <>
    <BoxWrapper bgColor={color} txtColor={textColor} overHover={hoverColor} hasBorder={border}>
        <Title>{title}</Title>
        <Main>
            <MainBold>{main}</MainBold>
            {measurment ? <Measurement>{measurment}</Measurement> : null }
        </Main>
    </BoxWrapper>
  </>
)

export default BoxComponent;


