import { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../components/input/InputText.component";
import SentComponent from "../../components/Sent/Sent.component";
import { createTeam } from "../../services/team.service";

const FormItem = styled.div`
    margin-bottom: 10px;
`;

const Button = styled.div`
    width: 100px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

interface InputTextProps{
    title?: string;
    users?: any[];
    fetchTeams?: () => void;
 }

const NewTeamFeature:React.FC<InputTextProps> = ({  users, fetchTeams })=> {

    const [notSent, setnotSent] = useState(true);
    const [teamName, setTeamName] =  useState('');
    const handleChapterLeader = (event: any) => {
        setTeamName(event.target.value);
    };

    const handleOptionChange = (option: any) => {
        setTeamLeader(option.value);
        setSelectedOption(option);
    };

    const handleSubmit = async()=> {
        setnotSent(false);
        await createTeam({ name: teamName, teamLeadId: teamLeader });
        fetchTeams && fetchTeams();
    }
    // const options = UsersList.map((item)=> { return {value: item.id, label: item.name }});
    const options = users.map((item) => {
        return {value: item.id, label: `${item.firstName} ${item.lastName}` };
      });
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [teamLeader, setTeamLeader] = useState(options[0].value);
  return <>
    {notSent ? <div><FormItem>
        <InputTextComponent onChildStateChange = {handleChapterLeader } fullWidth={true} label="Name" type="text"  placeholder="Type team unique name" />
    </FormItem>
    <FormItem>
    <DropdownSelectBox label="Team leader"  options={options} selectedOption={selectedOption} onChange={handleOptionChange} />
    </FormItem>
    <FormItem>
        <Button onClick = { () => handleSubmit() }>Create</Button>
    </FormItem></div> : <SentComponent title = "New team has been created!" /> }
  </>
};

export default NewTeamFeature;
