import "./styles/workflow.style.css";
import { useState } from "react";
import TabComponent from "../../../components/Tab/Tab.component";
import AttachedFiles from "../../../components/fileUploader/attachedFile.components";
import AssessmentsFeature from "../../Assessment/assessment.feature";
import styled from "styled-components";

const Tabs = styled.div`
  display: flex;
  width: 500px;
  margin-top: 30px;
  gap: 20px;
`;

const Content = styled.div`
  margin-top: 15px;
`;

export enum TabsEnum {
  Attachments = "ATTACHMENTS",
  Assesments = "ASSEEMENTS",
}

interface Props {
  actionId: string;
  files?: any;
  assessmentsData?: any;
}

const ActionDetailsTabFeature: React.FunctionComponent<Props> = ({
  files,
  assessmentsData,
  actionId,
}) => {
  const [active, setActive] = useState(1);

  const tabItems = [
    {
      id: 1,
      title: "Attachments",
      content: <AttachedFiles files={files} />,
    },
    {
      id: 2,
      title: "Assessments",
      content: (
        <AssessmentsFeature
          assessments={assessmentsData.assessments ? assessmentsData.assessments : []}
          isActive={assessmentsData.isActive}
          threshold={assessmentsData.threshold}
          actionId={actionId}
        />
      ),
      badge: "AI Generated",
    },
  ];

  return (
    <>
      <Tabs>
        {tabItems.map(({ id, title, badge }) => (
          <TabComponent
            title={title}
            key={id}
            onItemClicked={() => setActive(id)}
            isActive={active === id}
            badge={badge}
          />
        ))}
      </Tabs>
      <Content>
        {tabItems && tabItems.map(({ id, content }) => {
          return active === id ? <div key={id}>{content}</div> : "";
        })}
      </Content>
    </>
  );
};

export default ActionDetailsTabFeature;
