import * as React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import './styles/catalog-details.css';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getAssetById} from "../../services/catalog.service";
import styled from "styled-components";
import {convertDate} from "../../utils/date";
import CreateButtonComponent from "../../components/Buttons/CreateButton.component";
import ModalComponent from "../../components/modal/modal.component";
import useModal from "../../hooks/useModal";
import {getUsers} from "../../services/user.service";
import AssignAssetFeature from "./assign-asset.feature";

const CatalogDetailsPage: React.FunctionComponent = () => {
    const {isShowing, toggle} = useModal();
    const [asset, setAsset] = useState<any>();

    const [users, setUsers] = useState<{id: number | string; firstName: string}[]>([]);
    useEffect(()=> {
        async function getLeaders() {
            const users = await getUsers();
            setUsers(users.users);
        }
        getLeaders();
    },[]);
    let { catalogId } = useParams();
    const fetchAssets = async () => {
        const assetsList = await getAssetById(catalogId);
        setAsset(assetsList);
    }
    useEffect(()=> {
        async function getAssets() {
            const assetsList = await getAssetById(catalogId);
            setAsset(assetsList)
        }
        getAssets();
    },[catalogId]);

   return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Asset Catalog Details page</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"></div>
            </div>

            <div className="controls-page-catalog">
                { asset ?
                <AssetDataWrapper>
                    <AssetTable>
                        <AssetTbody>
                        <AssetTr key = "name">
                            <AssetName>Name</AssetName>
                            <AssetValue>{ asset.name }</AssetValue>
                        </AssetTr>
                        <AssetTr key = "description">
                            <AssetName>Description</AssetName>
                            <AssetValue>{ asset.description }</AssetValue>
                        </AssetTr>
                        <AssetTr key = "status">
                            <AssetName>Status</AssetName>
                            <AssetValue>{ asset.status }</AssetValue>
                        </AssetTr>
                        <AssetTr key = "availability">
                            <AssetName>Availability</AssetName>
                            <AssetValue>{ asset.availability }</AssetValue>
                        </AssetTr>
                        <AssetTr key = "asset_type">
                            <AssetName>Asset Type</AssetName>
                            <AssetValue>{ asset.asset_type }</AssetValue>
                        </AssetTr>
                        <AssetTr key = "createdAt">
                            <AssetName>Created At</AssetName>
                            <AssetValue>{ convertDate(asset.createdAt) }</AssetValue>
                        </AssetTr>
                        <AssetTr key = "assigned">
                             <AssetName>Assigned to</AssetName>
                             <AssetValue>{ asset.assigned.firstName } { asset.assigned.lastName }</AssetValue>
                        </AssetTr>
                        </AssetTbody>
                    </AssetTable>

                </AssetDataWrapper> : null }
                <AssignAssetWrapper>
                <CreateButtonComponent textColor="#ffffff" color="#2a7aeb"  text="Assign To Employee" onClick={ toggle } />
                <ModalComponent
                    isShowing={isShowing}
                    hide={toggle}
                    title="Assign To Employee"
                >
                    <AssignAssetFeature fetchAssets={fetchAssets} users={users}  assetId={catalogId} title = 'Assign To Employee' />
                </ModalComponent>
                </AssignAssetWrapper>
                { asset && asset.history.length ?
                    <AssetHistory>
                        <AssetHead>Asset History</AssetHead>
                        <AssetHistoryTable>
                            <AssetHistoryThead>
                                <AssetHistoryThe>Employee</AssetHistoryThe>
                                <AssetHistoryThe>Status</AssetHistoryThe>
                                <AssetHistoryThe>Created At</AssetHistoryThe>
                                <AssetHistoryThe>Action</AssetHistoryThe>
                            </AssetHistoryThead>
                            <AssetHistoryTbody>
                                {asset.history.map((item, index) => (
                                    <AssetHistoryTr key={index}>
                                        <AssetHistoryTd>{item.user.firstName} {item.user.lastName}</AssetHistoryTd>
                                        <AssetHistoryTd>{item.status}</AssetHistoryTd>
                                        <AssetHistoryTd>{ convertDate(item.createdAt)}</AssetHistoryTd>
                                    </AssetHistoryTr>
                                ))}

                            </AssetHistoryTbody>
                        </AssetHistoryTable>
                    </AssetHistory> : null }
            </div>

        </DashboardLayout>
    </>
}
const AssignAssetWrapper = styled.div`
    width: 190px;
    margin-top: 20px;
`;
const AssetHistoryTable = styled.table`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;
    color: #121111;
`;
const AssetHistoryThead = styled.thead`
    display: flex;
    width: 600px;
    justify-content: space-between;
    align-items: center;
    background-color: #e0e0e0;
    padding: 10px;
`;
const AssetHistoryThe = styled.th`
    
`;
const AssetHistoryTbody = styled.tbody``;
const AssetHistoryTr = styled.tr`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 600px;
    background-color: #f5f5f5;
    margin-bottom: 2px;
`;
const AssetHistoryTd = styled.td`

`;

const AssetHistory = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 600px;
`;
const AssetHead = styled.div`
    font-weight: bolder;
`;
const AssetTbody = styled.tbody``;
const AssetDataWrapper = styled.div``;
const AssetTable = styled.table`
    width: 400px;
`;
const AssetTr = styled.tr`
    padding: 10px;
    border-bottom: 1px solid #c2c2c2;
`;
const AssetName = styled.td`
    background-color: #ebebeb;
    padding: 10px;
`;
const AssetValue = styled.td`
    padding: 10px;
`;
export default CatalogDetailsPage;
