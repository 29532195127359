import {useCallback, useState, useEffect} from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../../../components/input/InputText.component";
import SentComponent from "../../../../components/Sent/Sent.component";
import { createOnboarding } from "../../../../services/onboardings.service";
import {WorkTypeList} from "../../../../utils/data/workTypes.data";
import DatePickerComponent from "../../../../components/DatePicker/DatePicker.component";
const FormItem = styled.div`
    margin-bottom: 10px;
`;

const ErrorWrapper = styled.div`
  width: auto;
  height: 20px;
  margin-top: 20px;
  padding: 5px;
  background: #ff8f9a;
  border: 1px solid #a8323e;
`;

const Button = styled.div`
    min-width: 100px;
    max-width: 200px;
    padding: 8px;
    background-color: #2a7aeb;
    border-radius: 4px;
    color: #ffff;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

interface MainFormProps {
    isExpandedContent?: boolean;
}

const Mainform = styled.div<MainFormProps>`
    width: 500px;
`;
const SecondaryForm = styled.div`
    width: 300px;
    margin-left: 30px;
`;

const SecondaryTitle = styled.div`
    margin-top: 35px;
    font-size: 14px;
`;

const ActionsList = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    max-height: 600px; // choose appropriate height
    overflow-y: auto;
`;

const ActionTitle = styled.div<ActionItemProps>`
    width: 300px;
    background-color: ${props => props.bgcolor || "#e6eff5"};
    font-size: 14px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 10px;
`;

interface ActionItemProps {
    bgcolor?: string;
}

const PreOnboarding = styled.div`
    font-size: 10px;
    color: #ffffff;
`;

const ActionName = styled.div`
    font-size: 12px;
`;

const ActionItem = styled.div<ActionItemProps>`
    display: flex;
    flex-direction: row;
    width: 300px;
    background-color: ${props => props.bgcolor || "transparent"};
`;

const ActionListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

interface InputTextProps{
    title?: string;
    workflows?: any;
    positions?: any;
    teams?: any;
    fetchOnboardings?: () => void;
    setIsExpanded?: (value: boolean) => void;
 }

const CreateOnboardingFeature:React.FC<InputTextProps> = ({ title, workflows, setIsExpanded, positions, teams, fetchOnboardings })=> {
    const [notSent, setnotSent] = useState(true);
    const [isExpandedContent, setIsExpandedContent] = useState(false);
    const [error, setError] = useState(false);
    const handleSubmit = async ()=> {

        await createOnboarding({
            boardingType: "ONBOARDING",
            workflowId: workflowValue,
            fullname: `${firstName} ${lastName}`,
            email: email,
            teamId: teamValue,
            positionsId: positionValue,
            workType: workType,
            startDate: startDate,
            workflowActions: workflowActions,
         }).then(() => {
            setIsExpanded(false);
            setnotSent(false);
            fetchOnboardings && fetchOnboardings();
        }).catch(() => {
            setError(true);
        });
    }


    const handleDateChange = useCallback((date: string, actionId: string) => {
        setDates(prev => ({ ...prev, [actionId]: date }));

        // Updating the workflowActions state
        setWorkflowActions(prev => {
            return prev.map((action) => (
                action.actionId === actionId ? ({ ...action, dueDate: new Date(date) }) : action
            ));
        });
    }, []);

    const [firstName, setFirstname] = useState("");
    const [lastName, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [startDate, setStartDate] = useState("");
    const [workflowActions, setWorkflowActions] =useState<{ actionId: string, dueDate: Date }[]>([]);

    const defaultOptions = [{value: "", label: "Choose Workflow"}];
    const workflowsList = workflows.map((item) =>  { return {value: item.id, label: item.name}});
    const combinedOptions = [...defaultOptions, ...workflowsList];
    const [selectedWorkflowsOption, setSelectedWorkflowsOption] = useState(combinedOptions[0]);

    const teamList = teams.map((item) =>  { return {value: item.id, label: item.name}});
    const [selectedTeamsOption, setSelectedTeamsOption] = useState(teamList[0]);

    const positionsList = positions.length ? positions.map((item) =>  { return {value: item.id, label: item.name}}) : [{value: "", label: "Choose Position"}];
    const [selectedPositionsOption, setSelectedPositionOption] = useState(positionsList[0]);

    const workList = WorkTypeList.map((item) =>  { return {value: item.id, label: item.name}});
    const [setWorkType, setSelectedWorkList] = useState(workList[0]);    

    
    const handleFirstNameChange = (event: any) => {
        setFirstname(event.target.value);
    };

    const handleLastNameChange = (event: any) => {
        setLastname(event.target.value);
    };

    const handleDate = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePositionOptionChange = (option: any) => {
        setPosition(option.value);
        setSelectedPositionOption(option);
    };

    const handleWorkflowsOptionChange = (option: any) => {
        setIsExpanded(true);
        setIsExpandedContent(true);
        setSelectedWorkflowsOption(option);

        // Fetch the chosen workflow
        const selectedWorkflow = workflows.find(workflow => workflow.id === option.value);

        // Update the workflowActions state
        if (selectedWorkflow && Array.isArray(selectedWorkflow.actions)) {
            setWorkflowActions(selectedWorkflow.actions.map(action => ({ actionId: action.id, dueDate: dates[action.id] ? new Date(dates[action.id]) : [] })));
        } else {
            setWorkflowActions([]);
        }

        setWorkflowValue(option.value);
    };

    const handleWorkTypeChange = (option: any) => {

        setWorkTypes(option.value);
        setSelectedWorkList(option);
    };

    const handleTeamsOptionChange = (option: any) => {
        setTeamValue(option.value);
        setSelectedTeamsOption(option);
    };
    const [sortedActions, setSortedActions] = useState<any[]>([]);
    const [dates, setDates] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const selectedWorkflow = workflows.find(workflow => workflow.id === selectedWorkflowsOption?.value);

        if (selectedWorkflow) {
            const sortedActions = Array.isArray(selectedWorkflow.actions) ? [...selectedWorkflow.actions] : [];
            sortedActions.sort((a, b) => {
                const dateA = new Date(dates[a.id] || 0);
                const dateB = new Date(dates[b.id] || 0);
                return dateA.getTime() - dateB.getTime();
            });

            setSortedActions(sortedActions);
        }
    }, [selectedWorkflowsOption, workflows, dates]);


    const [positionValue, setPosition] = useState(positionsList[0].value);
    const [teamValue, setTeamValue] = useState(teamList[0].value);
    const [workflowValue, setWorkflowValue] = useState(workflowsList[0].value);

    const [workType, setWorkTypes] = useState(workList[0].value);
  return <Container>
    <Mainform>
    {title}
    {notSent ? <div>
        {error ? <ErrorWrapper>Something went wrong, try again!</ErrorWrapper> : null }
    <FormItem>
        <InputTextComponent label="First name" onChildStateChange = {handleFirstNameChange } fullWidth={true} type="text"  placeholder="First name..." />
    </FormItem>
        <FormItem>
            <InputTextComponent label="Last name" onChildStateChange = {handleLastNameChange } fullWidth={true} type="text"  placeholder="Last name..." />
        </FormItem>
    <FormItem>
        <InputTextComponent label="Invitation email" onChildStateChange = {handleEmailChange} fullWidth={true} type="text"  placeholder="User email..." />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Choose Workflows" options={workflowsList} selectedOption={selectedWorkflowsOption} onChange={handleWorkflowsOptionChange} />
    </FormItem>
    <FormItem>
        <DropdownSelectBox label="Choose position" options={positionsList} selectedOption={selectedPositionsOption} onChange={handlePositionOptionChange} />
    </FormItem>
    <FormItem>
    <DropdownSelectBox  label="Choose Team" options={teamList} selectedOption={selectedTeamsOption} onChange={handleTeamsOptionChange} />
    </FormItem>
    <FormItem>
        <DropdownSelectBox  label="Work Type" options={workList} selectedOption={setWorkType} onChange={handleWorkTypeChange} />
    </FormItem>
    <FormItem>
        <InputTextComponent label="Start Date" onChildStateChange = {handleDate} fullWidth={true} type="date"  placeholder="Choose Date" />
    </FormItem>
    <FormItem>
        <Button onClick = { () => handleSubmit() } >Start Onboarding</Button>
    </FormItem></div> : <SentComponent title="Invitation has been sent!" /> }
  </Mainform>
      { isExpandedContent && notSent ? <SecondaryForm>
      <SecondaryTitle>Choose due dates</SecondaryTitle>
          <ActionListWrapper>
              <ActionsList>
                  {
                      sortedActions.map((action, i) => (
                          <ActionItem  key={action.id + i}>
                              <ActionTitle bgcolor={startDate && new Date(dates[action.id]).getTime() < new Date(startDate).getTime() ? '#7ccf92' : ''}><ActionName>{action.name}</ActionName>
                                  {startDate && new Date(dates[action.id]).getTime() < new Date(startDate).getTime() ? <PreOnboarding>Pre-onboarding</PreOnboarding> : ''}
                              </ActionTitle>
                              <DatePickerComponent
                                  selectedDate={dates[action.id] || ''}
                                  showLabel={false}
                                  onDateChange={(date: string) => handleDateChange(date, action.id)}
                              />
                          </ActionItem>
                      ))
                  }
              </ActionsList>
          </ActionListWrapper>

      </SecondaryForm> : null }
  </Container>
};

export default CreateOnboardingFeature;
