import * as React from 'react';
import styled from "styled-components";
import ProfileImageComponent from '../../../components/ProfileImage/ProfileImage';
import { CalculatePercentageStatus, CalculateStatusColor } from '../../../libs/CalculatePercentageStatus';
import { BoardingStatus } from './Status.enun';
import {calculateDaysLeft} from "../../../utils/days-left.util";
import {toTitleCase} from "../../../utils/title-case.util";

const Iteam = styled.tr`
    height: 50px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        background-color: #dbdbdb;
    }
`;

const IdIteam = styled.td`
    text-align: center;
    width: 0;
`;

const ItemFname = styled.td`
    display: flex;
    margin-top: 10px;
    width: 200px;
`;
const StyledTd = styled.td`
  width: 100px;  
  &:last-child {
    text-align: left;
  }
`;

const StartsAt = styled.td`
  width: 100px;  
  color: #37387d;  
  &:last-child {
    text-align: left;
  }
`;

const Fname = styled.div`
    padding: 5px;
    padding-left: 10px;
    font-weight: bold;
    color:#474f5e;
`;

const SectionItem = styled.div`
    display: flex;
    flex-direction: column;
`;

const EmailItem = styled.div`
    padding-left: 10px;
    font-size: 13px;
    color: #ababab;
    padding-bottom: 7px;
`;

const Workflow =  styled.td`
    width: 220px;
`;

const Team =  styled.td`
    width: 180px;
`;

const Progress =  styled.td`
    width: 180px;
`;

const StartDateBox = styled.div<{ isOverdue: boolean, isToday: boolean }>`
    padding: 3px;
    border-radius: 5px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({isOverdue, isToday}) => {
        if (isOverdue) return '#ff7675';
        if (isToday) return '#fdcb6e';
        return '#00b894';
    }};
    color: white;
`;

const ProcessPercentage = styled.div<{color?: string}>`
    width: 100px;
    height: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: ${props => props.color};
`;

const ProfileImage = styled.div``;

const StatusContainer = styled.div<{status: string}>`
    border-radius: 4px;
    padding: 4px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${({ status }) => {
    switch (status) {
        case 'TODO':
            return '#b2bec3';
        case 'IN PROGRESS':
            return '#fdcb6e';
        case 'COMPLETED':
            return 'green';
        default:
            return '#00b894';
    }
}};
`;

interface Props {
    id?: string;
    position: string;
    firstName: string;
    status: BoardingStatus;
    percentage: number;
    lastName: string;
    team: string;
    workflow: string;
    createdAt: string;
    startDate: string;
    onClick: () => void;
}
const OnboardingTableItem: React.FunctionComponent<Props> = ({  id,position, firstName, status, percentage, lastName, team, startDate, createdAt, workflow, onClick }) => {
    const daysLeft = calculateDaysLeft(startDate);
    const isOverdue = daysLeft < 0;
    const isToday = daysLeft === 0;
    const text = isOverdue
        ? `${Math.abs(daysLeft)} days`
        : isToday
            ? `Today`
            : `${daysLeft} day${daysLeft !== 1 ? 's' : ''} left`;

    return <Iteam onClick={onClick} key={id}>
            <IdIteam></IdIteam>
            <ItemFname>
            <ProfileImage>
                <ProfileImageComponent firstName={ firstName } lastName = {lastName || ''} />
            </ProfileImage>
                <SectionItem><Fname>{ firstName } { lastName }</Fname> <EmailItem>{ position }</EmailItem></SectionItem>
            </ItemFname>
            <StyledTd>
                <StatusContainer status={status}>
                    {toTitleCase(status)}
                </StatusContainer>
            </StyledTd>
            <Team> { team } </Team>
            <Workflow> { workflow } </Workflow>
            <Progress><ProcessPercentage color={ CalculateStatusColor(CalculatePercentageStatus(percentage)) }>{ percentage }%</ProcessPercentage></Progress>
            <StartsAt>{  startDate }</StartsAt>
            <StartsAt>
                <StartDateBox isOverdue={isOverdue} isToday={isToday}>
                    {text}
                </StartDateBox>
            </StartsAt>
            <StyledTd>{  createdAt }</StyledTd>
        </Iteam>
}

export default OnboardingTableItem;
