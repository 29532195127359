import React, { useState } from "react";
import styled from "styled-components";
import DropdownSelectBox from "../../../components/DropdownSelectBox/DropdownSelectBox.component";
import InputTextComponent from "../../../components/input/InputText.component";
import SentComponent from "../../../components/Sent/Sent.component";
import TextBoxComponent from "../../../components/TextBox/TextBox.component";
import { createAction } from "../../../services/actions.service";
import FileUploader from "../../../components/fileUploader/fileUploader.component";

const FormItem = styled.div`
  margin-bottom: 10px;
`;

const FormItemActions = styled.div`
  margin-top: 20px;
`;

const ErroWapper = styled.div`
    border: 1px solid red;
    padding: 10px;
    color: black;
    background-color: #f8d7da;
    box-sizing: border-box;
`;

const Button = styled.div`
  min-width: 100px;
  max-width: 200px;
  padding: 8px;
  background-color: #2a7aeb;
  border-radius: 4px;
  color: #ffff;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
`;

interface InputTextProps {
  title?: string;
  users?: any[];
  fetchData?: () => void;
}

const CreateActionFeature: React.FC<InputTextProps> = ({
  users,
  fetchData,
}) => {
  const [notSent, setnotSent] = useState(true);
  const [actionName, setActionName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);

  const handleActionName = (event: any) => {
    setActionName(event.target.value);
  };

  const handleDescription = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      await createAction({
        name: actionName,
        assigneeId: assigneeName,
        reversible: reversible,
        actionType: actionType,
        description: description,
        files: uploadedFileData
      });     

      fetchData && fetchData();
      setnotSent(false);
    } catch (err) {
      setError(true)
    }
  };

  const options = users.map((item) => {
    return { value: item.id, label: `${item.firstName} ${item.lastName}` };
  });
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
    setAssigneeName(option.value);
  };

  const actionTypes = [
    {
      value: "HARDWARE_SOFTWARE",
      label: "Hardware and Software",
    },
    {
      value: "ACCOUNT",
      label: "Account Creation",
    },
    {
      value: "DOCUMENTATION_TRAINING",
      label: "Documentation and Training",
    },
    {
      value: "ACCESS_SECURITY",
      label: "Access and Security",
    },
    {
      value: "HR_PAYROLL",
      label: "HR and Payroll",
    },
    {
      value: "COMMUNICATION_COLLABORATION",
      label: "Communication and Collaboration",
    },
    {
      value: "COMPANY_POLICY_CULTURE",
      label: "Company Policies and Culture",
    },
    {
      value: "FACILITIES_WORKSPACE",
      label: "Facilities and Workspace",
    },
    {
      value: "IT_SUPPORT_HELPDESK",
      label: "IT Support and Helpdesk",
    },
    {
      value: "BENEFITS_PERKS",
      label: "Benefits and Perks",
    },
    {
      value: "LEGAL_COMPLIANCE",
      label: "Legal and Compliance",
    },
    {
      value: "FEEDBACK_SURVEYS",
      label: "Feedback and Surveys",
    },
    {
      value: "RESOURCE_ACCESS",
      label: "Resource Access",
    },
    {
      value: "PERSONALIZED_ONBOARDING",
      label: "Personalized Onboarding",
    },
    {
      value: "OTHER",
      label: "Other",
    },
  ];
  const [selectedActionTypesOption, setSelectedActionTypesOption] = useState(
    actionTypes[0]
  );
  const handleOptionPriorityChange = (option: any) => {
    setSelectedActionTypesOption(option);
    setActionActionType(option.value);
  };

  const reversibleList = [
    { value: "REVERSIBLE", label: "Yes" },
    { value: "NOT_REVERSIBLE", label: "No" },
  ];
  const [selectedReversibleOption, setSelectedReversibleOption] = useState(
    reversibleList[0]
  );
  const handleOptionReversibleChange = (option: any) => {
    setSelectedReversibleOption(option);
    setReversible(option.value);
  };

  const [uploadedFileData, setUploadedFileData] = useState([]);
  
  const handleFileUpload = (response: any) => {    
    setUploadedFileData(response);
  };

  const [assigneeName, setAssigneeName] = useState(options[0].value);
  const [actionType, setActionActionType] = useState(actionTypes[0].value);
  const [reversible, setReversible] = useState(reversibleList[0].value);
  return (
    <>
      {notSent ? (
        <div>
          <FormItem>
            {error && <ErroWapper>Error creating action</ErroWapper>}
            <InputTextComponent
              label="Action name"
              onChildStateChange={handleActionName}
              fullWidth={true}
              type="text"
              placeholder="name"
            />
          </FormItem>
          <FormItem>
            <TextBoxComponent              
              fullWidth={true}
              onChildStateChange={handleDescription}
              placeholder="description"
            />
          </FormItem>
          <FormItem>
            <DropdownSelectBox
              label="Assign to"
              options={options}
              selectedOption={selectedOption}
              onChange={handleOptionChange}
            />
          </FormItem>
          <FormItem>
            <DropdownSelectBox
              label="Action Type"
              options={actionTypes}
              selectedOption={selectedActionTypesOption}
              onChange={handleOptionPriorityChange}
            />
          </FormItem>
          <FormItem>
            <DropdownSelectBox
              label="Should be revoked after leaving work?"
              options={reversibleList}
              selectedOption={selectedReversibleOption}
              onChange={handleOptionReversibleChange}
            />
          </FormItem>
         
          { (selectedActionTypesOption.value === 'DOCUMENTATION_TRAINING')?
          <FormItem>             
            <FileUploader callback={handleFileUpload} label="Attachments"/>
          </FormItem> : null}
          <FormItemActions>
            <Button onClick={() => handleSubmit()}>Create Action</Button>
          </FormItemActions>
        </div>
      ) : (
        <SentComponent title="Action has been created!" />
      )}
    </>
  );
};

export default CreateActionFeature;
